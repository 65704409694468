import React, { ChangeEvent, ReactElement, useContext, useEffect, useState } from 'react';
import { SCRoot } from './styles';
import { IClaimBenefitProps } from './types';
import { FlexAlignItemsEnum, FormFieldWidthEnum } from 'enums/StyleTypes';
import { claimBenefitFormLabels, editFormLabels } from 'support/formLabels';
import {
    SCElementsWrapper,
    SCElementWrapper,
    SCErrorLabel,
    SCHRElement,
    SCTempDateFieldWrapper
} from 'styles/global-styles';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { TSTextField } from 'components/atoms/TSTextField';
import { TSAutocomplete } from 'components/atoms/TSAutocomplete';
import { TSButton } from 'components/atoms/TSButton';
import { TSButtonSizeEnum, TSButtonVariantsEnum } from 'enums/TSButtonVariants';
import { ClaimEditContext, IClaimEditType, IClaimEditTypeState } from 'context/ClaimEdit';
import { errorMapIU, formatCurrency, getNumberWithoutCommas } from 'utils/form/form';
import { MaxFieldLengths } from 'enums/MaxFieldLengths';
import { claimDecisions } from 'support/table';
import { IClaimsManagementClaimBenefitsData } from 'types/claims-management';
import { ClaimAdjudicationConfigsContext, IClaimAdjudicationConfigsTypeState } from 'context/ClaimAdjudicationConfigs';

const ClaimBenefit = ({ claimBenefit }: IClaimBenefitProps): ReactElement => {
    const { contextStateClaimEdit, updateContextStateClaimEdit, updateContextStateClaimEditBenefit } = useContext(
        ClaimEditContext
    ) as IClaimEditTypeState;
    const { contextStateClaimAdjudicationConfigs } = React.useContext(
        ClaimAdjudicationConfigsContext
    ) as IClaimAdjudicationConfigsTypeState;
    const [claimEditFormDataState, setClaimEditFormDataState] = React.useState<
        IClaimEditType | Record<string, unknown>
    >();
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [amountPayable, setAmountPayable] = useState<any>();
    const [amountPayableDisplay, setAmountPayableDisplay] = useState<IClaimsManagementClaimBenefitsData | any>();
    const [additionalInformation, setAdditionalInformation] = useState<IClaimsManagementClaimBenefitsData | any>();
    const [certificateEffectiveDate, setCertificateEffectiveDateError] = useState<string | null>(null);
    const [claimDecisionDateError, setClaimDecisionDateError] = useState<string | null>(null);
    const [dateDecisionSentError, setDateDecisionSentError] = useState<string | null>(null);
    const [externalSystemId, setExternalSystemId] = useState<IClaimsManagementClaimBenefitsData | any>();
    const [visibleState, setVisibleState] = useState(false);
    const filteredArray: IClaimsManagementClaimBenefitsData[] = contextStateClaimEdit.ClaimBenefits.filter(
        (element) => {
            return element.IsDeleted !== true;
        }
    );

    useEffect(() => {
        claimBenefit.IsDeleted && setVisibleState(claimBenefit.IsDeleted);
    }, [claimBenefit]);
    useEffect(() => {
        const contextStateClaimEditDeepCopy = structuredClone(contextStateClaimEdit);
        setClaimEditFormDataState(contextStateClaimEditDeepCopy);
        claimEditFormDataState &&
            JSON.stringify(claimEditFormDataState) !== JSON.stringify(contextStateClaimEdit) &&
            setIsFormDirty(true);
    }, [contextStateClaimEdit, isFormDirty]);
    useEffect(() => {
        setAmountPayableDisplay({ value: formatCurrency(claimBenefit.AmountPayable) });
        setAdditionalInformation({ value: claimBenefit.DecisionAdditionalInfo });
        setExternalSystemId({ value: claimBenefit.ExternalSystemIdentifier as string });
    }, [claimBenefit]);
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::ClaimBenefit:::::::::::::::::::::::::::::::::',
    //     '\n::claimBenefit::',
    //     claimBenefit,
    //     '\n::claimBenefit.FastTracked::',
    //     claimBenefit.FastTracked,
    //     '\n::claimBenefit.FastTracked::',
    //     claimBenefit.FastTracked,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <SCRoot visibleState={visibleState}>
            <SCElementsWrapper>
                <SCElementWrapper
                    widthAmount={FormFieldWidthEnum.QUADRUPLE}
                    flexAlignment={FlexAlignItemsEnum.FLEX_END}
                >
                    <TSButton
                        ariaLabel="Remove Benefit button"
                        tsVariant={TSButtonVariantsEnum.MINUS}
                        size={TSButtonSizeEnum.MEDIUM}
                        onClick={(): void => {
                            claimBenefit.IsDeleted = true;
                            updateContextStateClaimEditBenefit(claimBenefit);
                        }}
                        disabled={filteredArray.length <= 1}
                    >
                        Remove Benefit
                    </TSButton>
                </SCElementWrapper>
            </SCElementsWrapper>
            <SCElementsWrapper>
                <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                    <TSTextField
                        id={'benefitId'}
                        ariaLabel={claimBenefitFormLabels.benefitId}
                        label={claimBenefitFormLabels.benefitId}
                        value={claimBenefit?.ClaimBenefitId}
                        readOnly={true}
                        placeholder={claimBenefit?.ClaimBenefitId}
                    ></TSTextField>
                </SCElementWrapper>
                <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                    <TSTextField
                        id={'externalSystemIdentifier'}
                        label={claimBenefitFormLabels.externalSystemIdentifier}
                        ariaLabel={claimBenefitFormLabels.externalSystemIdentifier}
                        value={externalSystemId?.value}
                        onChange={(fieldEvent): void => {
                            setExternalSystemId({
                                id: fieldEvent.currentTarget.id,
                                value: fieldEvent.currentTarget.value
                            });
                            fieldEvent.currentTarget.value && setIsFormDirty(true);
                        }}
                        onBlur={(): void => {
                            claimBenefit.ExternalSystemIdentifier = externalSystemId.value
                                ? externalSystemId.value
                                : '';
                            updateContextStateClaimEditBenefit(claimBenefit);
                            updateContextStateClaimEdit({
                                ...contextStateClaimEdit,
                                isDirty: true
                            });
                        }}
                        placeholder={editFormLabels.loanNumber}
                        maxLength={MaxFieldLengths.GENERAL_MAX}
                    ></TSTextField>
                </SCElementWrapper>
                <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                    <SCTempDateFieldWrapper>
                        <DatePicker
                            label={claimBenefitFormLabels.dateCertificateEffective}
                            className="react-datepicker-filter"
                            views={['month', 'day', 'year']}
                            defaultValue={null}
                            value={
                                claimBenefit?.CertificateEffectiveDateTimeUTC
                                    ? dayjs(claimBenefit?.CertificateEffectiveDateTimeUTC)
                                    : null
                            }
                            onChange={(value): void => {
                                if (value && !isNaN(value.date())) {
                                    claimBenefit.CertificateEffectiveDateTimeUTC = dayjs(value).toISOString();
                                    updateContextStateClaimEditBenefit(claimBenefit);
                                    updateContextStateClaimEdit({
                                        ...contextStateClaimEdit,
                                        isDirty: true
                                    });
                                }
                            }}
                            onError={(value): void => {
                                value != null
                                    ? setCertificateEffectiveDateError(errorMapIU['dateError'])
                                    : setCertificateEffectiveDateError(null);
                            }}
                        />
                    </SCTempDateFieldWrapper>
                    <SCErrorLabel>{certificateEffectiveDate}</SCErrorLabel>
                </SCElementWrapper>
                <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                    <TSAutocomplete
                        id={'benefitDecision'}
                        label={claimBenefitFormLabels.benefitDecision}
                        ariaLabel={claimBenefitFormLabels.benefitDecision}
                        options={claimDecisions}
                        value={claimBenefit?.DecisionStatus as string}
                        onChange={(event, value): void => {
                            claimBenefit.DecisionStatus = value ? value : '';
                            updateContextStateClaimEditBenefit(claimBenefit);
                            updateContextStateClaimEdit({
                                ...contextStateClaimEdit,
                                isDirty: true
                            });
                        }}
                    ></TSAutocomplete>
                </SCElementWrapper>
                <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                    <TSTextField
                        id={'amountPayable'}
                        label={claimBenefitFormLabels.amountPayable}
                        ariaLabel={claimBenefitFormLabels.amountPayable}
                        value={amountPayableDisplay?.value}
                        onChange={(fieldEvent: ChangeEvent<HTMLInputElement>): void => {
                            setAmountPayable({
                                id: fieldEvent.currentTarget.id,
                                value: fieldEvent.currentTarget.value
                            });
                            setAmountPayableDisplay({
                                id: amountPayableDisplay?.id,
                                value: fieldEvent.currentTarget.value
                            });
                        }}
                        onBlur={(): void => {
                            claimBenefit.AmountPayable = amountPayable?.value
                                ? getNumberWithoutCommas(amountPayable?.value)
                                : '0';
                            updateContextStateClaimEditBenefit(claimBenefit);
                            updateContextStateClaimEdit({
                                ...contextStateClaimEdit,
                                isDirty: true
                            });
                            setAmountPayableDisplay({
                                id: amountPayableDisplay?.id,
                                value: formatCurrency(amountPayable?.value)
                            });
                        }}
                        placeholder={claimBenefitFormLabels.amountPayable}
                        startAdornment={<span>$</span>}
                        maxLength={MaxFieldLengths.GENERAL_MAX}
                    ></TSTextField>
                </SCElementWrapper>
                <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                    <SCTempDateFieldWrapper>
                        <DatePicker
                            label={claimBenefitFormLabels.claimDecisionDate}
                            className="react-datepicker-filter"
                            views={['month', 'day', 'year']}
                            defaultValue={null}
                            value={claimBenefit?.DecisionDateTimeUTC ? dayjs(claimBenefit?.DecisionDateTimeUTC) : null}
                            onChange={(value): void => {
                                if (value && !isNaN(value.date())) {
                                    claimBenefit.DecisionDateTimeUTC = dayjs(value).toISOString();
                                    updateContextStateClaimEditBenefit(claimBenefit);
                                    updateContextStateClaimEdit({
                                        ...contextStateClaimEdit,
                                        isDirty: true
                                    });
                                }
                            }}
                            onError={(value): void => {
                                value != null
                                    ? setClaimDecisionDateError(errorMapIU['dateError'])
                                    : setClaimDecisionDateError(null);
                            }}
                        />
                    </SCTempDateFieldWrapper>
                    <SCErrorLabel>{claimDecisionDateError}</SCErrorLabel>
                </SCElementWrapper>
                <SCElementWrapper widthAmount={FormFieldWidthEnum.TRIPLE}>
                    <TSAutocomplete
                        id={'decisionReason'}
                        label={claimBenefitFormLabels.decisionReason}
                        ariaLabel={claimBenefitFormLabels.decisionReason}
                        options={contextStateClaimAdjudicationConfigs?.decisionReasons}
                        value={claimBenefit.DecisionReason}
                        onChange={(event, value): void => {
                            claimBenefit.DecisionReason = value ? value : '';
                            updateContextStateClaimEditBenefit(claimBenefit);
                            updateContextStateClaimEdit({
                                ...contextStateClaimEdit,
                                isDirty: true
                            });
                        }}
                    ></TSAutocomplete>
                </SCElementWrapper>
                <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                    <SCTempDateFieldWrapper>
                        <DatePicker
                            label={claimBenefitFormLabels.dateDecisionSent}
                            className="react-datepicker-filter"
                            views={['month', 'day', 'year']}
                            defaultValue={null}
                            value={
                                claimBenefit?.DecisionEmailSentDateTimeUTC
                                    ? dayjs(claimBenefit?.DecisionEmailSentDateTimeUTC)
                                    : null
                            }
                            onChange={(value): void => {
                                if (value && !isNaN(value.date())) {
                                    claimBenefit.DecisionEmailSentDateTimeUTC = dayjs(value).toISOString();
                                    updateContextStateClaimEditBenefit(claimBenefit);
                                    updateContextStateClaimEdit({
                                        ...contextStateClaimEdit,
                                        isDirty: true
                                    });
                                }
                            }}
                            onError={(value): void => {
                                value != null
                                    ? setDateDecisionSentError(errorMapIU['dateError'])
                                    : setDateDecisionSentError(null);
                            }}
                        />
                    </SCTempDateFieldWrapper>
                    <SCErrorLabel>{dateDecisionSentError}</SCErrorLabel>
                </SCElementWrapper>
                <SCElementWrapper widthAmount={FormFieldWidthEnum.QUADRUPLE}>
                    <TSTextField
                        id={'additionalInfo'}
                        label={claimBenefitFormLabels.decisionAdditionalInformation}
                        placeholder={claimBenefitFormLabels.decisionAdditionalInformation}
                        ariaLabel={claimBenefitFormLabels.decisionAdditionalInformation}
                        value={additionalInformation?.value}
                        defaultValue={additionalInformation?.value}
                        onChange={(fieldEvent: ChangeEvent<HTMLInputElement>): void => {
                            setAdditionalInformation({
                                id: fieldEvent.currentTarget.id,
                                value: fieldEvent.currentTarget.value
                            });
                        }}
                        onBlur={(): void => {
                            claimBenefit.DecisionAdditionalInfo = additionalInformation.value
                                ? additionalInformation.value
                                : '';
                            updateContextStateClaimEditBenefit(claimBenefit);
                            updateContextStateClaimEdit({
                                ...contextStateClaimEdit,
                                isDirty: true
                            });
                        }}
                        multiline={true}
                        minRows={5}
                        maxLength={MaxFieldLengths.GENERAL_MAX}
                    />
                </SCElementWrapper>
                <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                    <TSAutocomplete
                        id={'fastTracked'}
                        label={editFormLabels.fastTracked}
                        ariaLabel={editFormLabels.fastTracked}
                        options={[true, false]}
                        value={claimBenefit?.FastTracked}
                        getOptionLabel={(fastTrackedBool): string =>
                            fastTrackedBool === true ? 'Yes' : fastTrackedBool === false ? 'No' : ''
                        }
                        onChange={(event, value): void => {
                            claimBenefit.FastTracked = value;
                            updateContextStateClaimEditBenefit(claimBenefit);
                            updateContextStateClaimEdit({
                                ...contextStateClaimEdit,
                                isDirty: true
                            });
                        }}
                    ></TSAutocomplete>
                </SCElementWrapper>
            </SCElementsWrapper>
            <SCHRElement />
        </SCRoot>
    );
};

export { ClaimBenefit };
